//EventPage.jsx
import { Box, Container, Stack, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopBar from "../components/AppBar/TopBar";
import aidKit from "../Assets/Images/aidKit.png";
import { toast } from "react-toastify";
import MobileAppBar from "../components/AppBar/Mobile/TopBar/MobileAppBar";
import MobileNavBar from "../components/AppBar/Mobile/NavBar/MobileNavBar";
import FooterTablet from "../components/Panel/Footer/Tablet/FooterTablet";

import { getBySlug } from "../Data/event";
import { testPlan, closePlaces, openPlaces } from "../Data/plan";
import { checkAdmin } from "../Data/admin";
import {
  makeReservation,
  clearReservation,
  getReservationByPlan,
} from "../Data/reservation";

import Loading from "../components/Common/Loading";

import SeatchartJS, { Options } from "seatchart";
import Seatchart from "../components/Seatchart/Seatchart.js";

import "seatchart/dist/seatchart.min.css";

import smallTicket from "../Assets/Images/smallTicket.png";
import AutoInput from "../components/AutoComplete/AutoInput";
import seats from "../Assets/Images/seats.png";

import classes from "../Assets/Styles/eventpage.module.css";
import Footer from "../components/Panel/Footer/Footer";
import { MdLocationOn } from "react-icons/md";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import testmap from "../components/Seatchart/testmap.json";
import { Helmet } from "react-helmet";

const EventPageDesktop = () => {
  const seatchartRef = useRef();
  const navigate = useNavigate();
  const { slug } = useParams();
  const arreee = [0, 1, 2, 3, 4, 5, 6];
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [thisEvent, setEvent] = useState(null);
  const [eventMap, setEventMap] = useState(null);
  const [standart, setStandart] = useState(null);
  const [trueAdmin, setTrueAdmin] = useState(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 576px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });

  const verifyAdmin = async () => {
    const checkRes = await checkAdmin();
    if (checkRes && checkRes.val) {
      setTrueAdmin(checkRes.val);
    }
  };

  const defaultPlan = {
    map: {
      rows: 12,
      columns: 38,
      seatTypes: {
        default: {
          type: "default",
          label: "Economy",
          cssClass: "second-class",
          price: 15,
        },
        first: {
          type: "first",
          label: "First Class",
          cssClass: "first-class",
          price: 25,
          seatRows: [0, 1, 2],
        },
        reduced: {
          type: "reduced",
          label: "Reduced",
          cssClass: "third-class",
          price: 10,
          seatRows: [7, 8, 9],
        },
      },
      // disabledSeats: [
      //   { row: 0, col: 0 },
      //   { row: 0, col: 9 },
      // ],
      reservedSeats: [
        { row: 0, col: 3 },
        { row: 0, col: 4 },
      ],
      // selectedSeats: [
      //   { row: 0, col: 5 },
      //   { row: 0, col: 6 },
      // ],
      rowSpacers: [3, 7],
      columnSpacers: [5],
    },
  };

  const closeSeats = async () => {
    const closeReq = {
      places: seatchartRef.current.store.cart.map((it) => it.index),
      plan: thisEvent.plan._id,
    };
    await closePlaces(closeReq).then((r) => {
      if (r) {
        fetchEvent(slug);
        toast.success("Places Reserved");
      } else toast.error("Error occureed");
    });
  };

  const openSeats = async () => {
    const openReq = {
      places: seatchartRef.current.store.cart.map((it) => it.index),
      plan: thisEvent.plan._id,
    };
    await openPlaces(openReq).then((r) => {
      if (r) {
        fetchEvent(slug);
        toast.success("Places Opened");
      } else toast.error("Error occureed");
    });
  };

  const reserveSeats = async (tickets) => {
    let result = false;
    let prevReservation = await localStorage.getItem("resId");
    if (tickets.length) {
      let res = {
        plan: { _id: thisEvent.plan._id },
        tickets: [...tickets],
      };
      if (prevReservation) {
        await clearReservation(prevReservation).then(async (r) => {
          if (!r) return;
          else
            await makeReservation(res).then((r) => {
              result = r?._id;
            });
        });
      } else
        await makeReservation(res).then((r) => {
          result = r?._id;
        });
    } else {
      if (prevReservation)
        await clearReservation(prevReservation).then((r) => {
          result = r;
        });
    }
    return result;
  };

  const getTruePlace = (plan, row, col) => {
    let { rows, colums, disabledSeats } = plan.map;
    // row = 1;
    //col = 14
    let disabledHere = disabledSeats.filter((it) => it.row == row);
    let disabledBefore = disabledHere.filter((it) => it.col < col);
    let disabledAmount = disabledBefore.length;
    let trueDisabled = col - disabledAmount;
    return trueDisabled;
  };

  const submitClick = async () => {
    if (thisEvent.saleUrl) {
      window.location.href = thisEvent.saleUrl;
      return;
    }
    let tickets = [];
    if (!!seatchartRef.current) {
      //Complicated Plan
      let cart = seatchartRef.current.store.cart;
      cart.forEach((item, i) => {
        tickets.push({
          event: thisEvent._id,
          type: item.type,
          row: item.index.row + 1,
          seat: item.index.col + 1,
          trueSeat:
            getTruePlace(thisEvent.plan, item.index.row, item.index.col) + 1,
          trueLabel:
            item.label.substring(0, 1) +
            (getTruePlace(thisEvent.plan, item.index.row, item.index.col) + 1),
          label: item.label,
        });
      });
    } else {
      //Simple Plan
      Object.keys(standart).map((it, ind) => {
        let thistype = thisEvent.plan.standartSeatTypes.find(
          (fit) => fit.types === it
        );

        let am = standart[it];
        while (am > 0) {
          tickets.push({
            event: thisEvent._id,
            type: thistype.types,
            label: thistype.label,
          });
          am--;
        }
      });
    }

    await reserveSeats(tickets).then(async (r) => {
      if (r) {
        if (tickets.length) {
          let cartString = JSON.stringify(tickets);
          await localStorage.setItem("cart", cartString);
          await localStorage.setItem("resId", r);
          window.dispatchEvent(new Event("storage"));
          navigate("/buyTicket");
        } else {
          await localStorage.removeItem("cart");
          await localStorage.removeItem("resId");
          window.dispatchEvent(new Event("storage"));
          window.location.reload();
        }
      } else {
        fetchEvent(slug);
        toast.error(t("error_try_again"));
      }
    });
  };

  const fetchEvent = async (s) => {
    let ev = await getBySlug(s);
    let standartQuantities = {};
    if (ev?.plan) {
      let reservations = await getReservationByPlan(ev.plan._id);

      if (ev.plan.isSimple) {
        ev.plan.standartSeatTypes.map((it, ind) => {
          standartQuantities[it.types] = 0;
        });
        setStandart(standartQuantities);
      }

      let plan = ev.plan;
      let newPlan = { ...plan };
      let tickets = [];
      reservations.map((it) => {
        tickets = [...tickets, ...it.tickets];
      });
      if (tickets.length) {
        if (plan.isSimple) {
          let types = [...plan.standartSeatTypes];
          tickets.forEach((tit, tind) => {
            let thisTypeIndex = types.findIndex((it) => it.types == tit.types);
            if (thisTypeIndex > -1) {
              let thisType = { ...types[thisTypeIndex] };
              thisType.sold++;
              types[thisTypeIndex] = thisType;
            }
          });
          newPlan = { ...plan, standartSeatTypes: types };
        } else {
          let map = plan.map;
          let reservedSeats = [...map.reservedSeats];
          let newMap = null;
          tickets.forEach((tit, tind) => {
            reservedSeats.push({ row: tit.row - 1, col: tit.seat - 1 });
          });
          newMap = { ...map, reservedSeats: reservedSeats };
          newPlan = { ...plan, map: newMap };
        }
      }
      setEvent({ ...ev, plan: newPlan });
    }
  };

  const updateAddToCartAmout = (val, variant) => {
    let restAmount = variant.amount - variant.sold;
    let limitAmount = restAmount > 10 ? 10 : restAmount;

    let st = { ...standart };
    switch (val) {
      case "+":
        if (st[variant.types] < limitAmount) st[variant.types]++;
        break;
      case "-":
        if (st[variant.types] > 0) st[variant.types]--;
        break;
      case "":
        if (st[variant.types] < limitAmount) st[variant.types] = "";
        break;
      default:
        if (val > 0 && val < limitAmount) st[variant.types] = val;
        else if (val < 0) st[variant.types] = 1;
        else if (val > limitAmount) st[variant.types] = limitAmount;
    }
    setStandart(st);
  };

  useEffect(() => {
    fetchEvent(slug).then(() => {
      setTimeout(() => {
        let mapDiv = Array.from(document.getElementsByClassName("sc-map"))[0];
        mapDiv?.scroll((mapDiv.scrollWidth - mapDiv.clientWidth) / 2, 0);
      }, 100);
    });
    // testPlan();
    verifyAdmin();
  }, [window.location]);

  return (
    <Box
      className={classes.container + " eventPageContainer"}
      sx={{ display: "block" }}
    >
      {isMobile ? <MobileAppBar color="#f2c981" /> : <TopBar color="#f2c981" />}
      {/* <TopBar color="#ffd283" /> */}
      {/* Event Header */}
      {thisEvent?._id ? (
        <>
          <Helmet>
            <title>E-Ticket Al - {thisEvent?.title}</title>
            <meta property="og:title" content={thisEvent?.title} />
            <meta property="og:description" content={thisEvent?.description} />
            <meta property="og:image" content={CDN_URL + thisEvent?.image} />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Box className={classes.eventHeader}>
            <Container maxWidth="md" sx={{ height: "100%" }}>
              <Stack
                display="flex"
                className={classes.eventStack}
                sx={{ height: "100%" }}
              >
                {/* Event Poster */}
                <Box flex={1}>
                  <img
                    src={CDN_URL + thisEvent?.image}
                    alt="Event Poster"
                    className={classes.eventPoster}
                  />
                </Box>
                {/* Event Details */}
                <Box flex={2} className={classes.eventDetails}>
                  <Stack display="flex" direction="column">
                    <Typography
                      variant="h4"
                      color="#df0c62"
                      className={classes.titleStroke}
                    >
                      {thisEvent?.title}
                    </Typography>
                    <Stack display="flex" direction="row">
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(thisEvent?.location?.url, "_blank");
                        }}
                        flex={1}
                      >
                        <Typography
                          xs={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                          }}
                          variant="h6"
                          fontWeight="bold"
                          color="#df0c62"
                        >
                          <MdLocationOn /> {thisEvent?.location?.name}
                        </Typography>
                        <Typography paragraph color="#df0c62">
                          {thisEvent?.startDate.split("T")[0] +
                            " " +
                            thisEvent?.startDate.split("T")[1]}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box className={classes.redDash}></Box>
                    {/* Event Type */}
                    <Typography paragraph color="#df0c62">
                      {thisEvent?.type}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Container>
          </Box>

          {/* Middle Section */}
          <Container
            maxWidth={thisEvent?.plan?.map?.rows ? "xl" : "md"}
            className={classes.middleSection}
          >
            {/* Parent Stack */}
            <Stack display="flex" direction="column" spacing={1}>
              {/* Headline */}
              <Stack display="flex" direction="row" justifyContent="center">
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    textAlign="end"
                    color="#df0c62"
                  >
                    {t("Event Details")}
                  </Typography>
                </Box>
                <Box flex={5} className={classes.yellowLine}></Box>
              </Stack>
              {/* Event Description */}
              <Box className={classes.eventDescription}>
                <Typography
                  paragraph
                  sx={{ textAlign: "center", whiteSpace: "pre-wrap" }}
                  color="#df0c62"
                >
                  {thisEvent?.description}
                </Typography>
                <Typography
                  paragraph
                  sx={{ textAlign: "center", whiteSpace: "pre-wrap" }}
                  color="#df0c62"
                >
                  {t("Promotion")}
                  {" - "}
                  {thisEvent.promotion.type === "fixed" && (
                    <label>
                      {t(`${thisEvent?.promotion.type}_discount`, {
                        amount: thisEvent?.promotion.value,
                      })}
                      {thisEvent.currency.symbol}
                    </label>
                  )}
                  {thisEvent.promotion.type === "percentage" && (
                    <label>
                      {t(`${thisEvent?.promotion.type}_discount`, {
                        amount: thisEvent?.promotion.value,
                      })}
                      %
                    </label>
                  )}
                  {thisEvent.promotion.type === "bogo" && (
                    <label>
                      {t(`${thisEvent?.promotion.type}_discount`, {
                        buyQuantity: thisEvent?.promotion.buyQuantity,
                        getFreeQuantity: thisEvent?.promotion.getFreeQuantity,
                      })}
                    </label>
                  )}
                </Typography>
              </Box>

              {/* Event Header */}
              <Stack display="flex" direction="row" justifyContent="center">
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    textAlign="end"
                    color="#df0c62"
                  >
                    {thisEvent.isEnded === false
                      ? t("Buy Ticket")
                      : t("event_ended")}
                  </Typography>
                </Box>
                <Box flex={10} className={classes.yellowLine}></Box>
                {trueAdmin ? (
                  <div>
                    <div
                      style={{
                        marginLeft: "30px",
                        textAlign: "right",
                        float: "right",
                        cursor: "pointer",
                        color: "#df2862",
                      }}
                      onClick={closeSeats}
                    >
                      Close Picked Seats
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        textAlign: "right",
                        float: "right",
                        cursor: "pointer",
                        color: "#df2862",
                      }}
                      onClick={openSeats}
                    >
                      Open Picked Seats
                    </div>
                  </div>
                ) : null}
              </Stack>

              {thisEvent && thisEvent.plan && thisEvent.isEnded === false ? (
                <>
                  {thisEvent.plan.isSimple ? (
                    <div style={{ margin: "50px 0", color: "#df0b62" }}>
                      {thisEvent.plan.standartSeatTypes.map(
                        (variant, index) => (
                          <div
                            key={index}
                            className={
                              "d-flex flex-column flex-lg-row justify-content-between align-items-center my-3 h-5 h-lg-0" +
                              (variant.amount - variant.sold == 0
                                ? " disabledDiv"
                                : null)
                            }
                          >
                            <div
                              className="centeredFlex justify-content-between"
                              style={{
                                minWidth: "300px",
                                width: "100%",
                                padding: "10px 0",
                              }}
                            >
                              <p style={{ marginBottom: 0 }}>{variant.label}</p>
                              <p
                                className="text-end text-md-start"
                                style={{
                                  width: "100px",
                                  marginBottom: 0,
                                  margin: "0 10px",
                                }}
                              >
                                {thisEvent.currency.symbol + "" + variant.price}
                              </p>
                            </div>
                            {variant.amount -
                            variant.sold -
                            standart[variant.types] ? (
                              <div
                                className="centeredFlex justify-content-between"
                                style={{ minWidth: "300px", width: "40%" }}
                              >
                                <div
                                  className="centeredFlex"
                                  style={{
                                    width: "200px!important",
                                    margin: "0 10px",
                                  }}
                                >
                                  <div className="input-group-prepend">
                                    <button
                                      onClick={() => {
                                        updateAddToCartAmout("-", variant);
                                      }}
                                      className="quantityBut btn btn-outline-secondary"
                                      type="button"
                                    >
                                      -
                                    </button>
                                  </div>
                                  <input
                                    value={standart[variant.types]}
                                    onChange={(e) => {
                                      updateAddToCartAmout(
                                        e.target.value,
                                        variant
                                      );
                                    }}
                                    style={{
                                      width: "40px",
                                      margin: "0 10px",
                                      color: "#df0b62",
                                      border: "1px solid #ffd283",
                                    }}
                                  />
                                  {standart[variant.types] < 10 ? (
                                    <div className="input-group-append">
                                      <button
                                        onClick={() => {
                                          updateAddToCartAmout("+", variant);
                                        }}
                                        className="quantityBut btn btn-outline-secondary"
                                        type="button"
                                      >
                                        +
                                      </button>
                                    </div>
                                  ) : null}
                                </div>

                                <p
                                  style={{
                                    marginBottom: 0,
                                    margin: "0 10px",
                                    width: "70px",
                                    display: "none",
                                  }}
                                >
                                  {t("Rest Amount")}{" "}
                                  {variant.amount -
                                    variant.sold -
                                    standart[variant.types]}
                                </p>
                              </div>
                            ) : (
                              <div
                                className="centeredFlex justify-content-between"
                                style={{ minWidth: "300px", width: "40%" }}
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    margin: "0 10px",
                                    width: "70px",
                                  }}
                                >
                                  {t("SoldOut")}
                                </p>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <>
                      <Seatchart
                        ref={seatchartRef}
                        options={thisEvent.plan}
                        // options={testmap}
                        curr={thisEvent.currency.symbol}
                        planScheme={thisEvent.planScheme}
                        planImage={thisEvent.planImage}
                        trueAdmin={trueAdmin}
                        // promotion={thisEvent.promotion}
                      />
                    </>
                  )}
                </>
              ) : null}

              {/* End Of Parent Stack */}
            </Stack>
            {/* <img src={smallTicket} alt="Buy" className={classes.buyTicket} /> */}
            {thisEvent.isEnded === false ? (
              <>
                <Box
                  className={classes.buyTicketContainer}
                  // onClick={() => navigate("/buyTicket")}
                >
                  <Box className={classes.buy}>
                    <Typography
                      onClick={() => {
                        submitClick();
                      }}
                      textAlign="center"
                      className={classes.buyText}
                    >
                      {t("Buy")}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : null}

            {/* End of Parent Container */}
          </Container>
        </>
      ) : (
        <Loading />
      )}
      {/* Map Site */}
      <Box>{/* End of Site Map */}</Box>

      {isBigScreen ? (
        <Footer />
      ) : isTablet ? (
        <div className="mt-5">
          <FooterTablet />
        </div>
      ) : isMobile ? (
        <div className="mt-5">
          <MobileNavBar />
        </div>
      ) : null}
    </Box>
  );
};

export default EventPageDesktop;
