import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  listLatest,
  ticketCancel,
  onResend,
  orderCancel,
  allOrderCancel,
} from "../../../../../Data/order";
import {
  fetchOneDetailed,
  listAllDetailed,
  remindCountryBased,
} from "../../../../../Data/event";
import {
  ExpandMore,
  FileDownloadOutlined,
  Close,
  Email,
  Save,
} from "@mui/icons-material";
import classes from "./styles.module.css";
import { BsTrash } from "react-icons/bs";
import SearchBar from "../../../SearchBar/SearchBar";
import AModal from "../../../Modal/AModal";
import { jsonToPlainText, Options } from "json-to-plain-text";
import Spinner from "../../../../Common/Spinner";

import { useTranslation } from "react-i18next";
import { showDiscountPrice } from "../../../../../Services/helpers";
import { saveImage } from "../../../../../Data/general";
import AccordionDetailsSkeleton from "./AccordionDetailsSkeleton";
import { saveAs } from "file-saver";
import axios from "axios";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";
const SaleDesktop = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [allorders, setallOrders] = useState([]);
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimer, setSearchTimer] = useState(null);
  const [showEnded, setShowEnded] = useState(false);
  const navigate = useNavigate();
  const CDN_URL = process.env.REACT_APP_CDN_URL;

  const [showOrderCancel, setShowOrderCancel] = useState(false);
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [refund, setRefund] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingCancelOrder, setPendingCancelOrder] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [customImage, setCustomImage] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [isReturnAllTicketsModalOpen, setIsReturnAllTicketsModalOpen] =
    useState(false);
  const [notifyAllCustomer, setNotifyAllCustomer] = useState(false);
  const [allRefund, setAllRefund] = useState(false);
  const [allCancelReason, setAllCancelReason] = useState("");

  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    if (searchQuery != "") setOrders(await listLatest(searchQuery));
    else setOrders(await listLatest());

    setEvents(await listAllDetailed());
    setIsLoading(false);
  };

  const toggleEve = (ind) => {
    let evesList = [...events];
    let thiseveactive = evesList.findIndex((it) => it._id == ind);
    if (evesList[thiseveactive].active) evesList[thiseveactive].active = false;
    else evesList[thiseveactive].active = true;
    setEvents(evesList);
    getEventDetailed(ind);
  };

  const getEventDetailed = async (id) => {
    let loaded = false;
    const res = await fetchOneDetailed(id);
    let thisEvent = events.find((it) => it._id == id);
    let active = false;
    loaded = true;
    if (thisEvent.active) {
      active = true;
    } else {
      active = false;
    }

    setEvents((prev) =>
      prev.map((p) => {
        if (p._id === res._id)
          return { ...res, active: active, loaded: loaded };
        else return p;
      })
    );
  };

  const onCancel = async (id) => {
    await ticketCancel(id).then((r) => {
      if (!r) toast.error(t("error_try_again"));
      else {
        toast.success(t("ticket_canceled"));
        fetch();
      }
    });
  };

  const onAllCancelOrder = async () => {
    setPendingCancelOrder(true);
    const body = {
      _id: selectedEvent,
      notifyCustomer: notifyAllCustomer,
      cancelReason: allCancelReason,
      refund: allRefund,
    };
    console.log(body);
    await allOrderCancel(body).then((r) => {
      if (!r) {
        setPendingCancelOrder(false);
        toast.error(t("error_try_again"));
      } else {
        toast.success(t("order_canceled"));
        fetch();
        setIsReturnAllTicketsModalOpen(false);
        setPendingCancelOrder(false);
        setSelectedEvent(null);
      }
    });
  };

  const onCancelOrder = async () => {
    setPendingCancelOrder(true);
    const body = {
      _id: orderToCancel._id,
      notifyCustomer,
      cancelReason,
      customerEmail:
        customerEmail || orderToCancel?.tickets[0]?.customer?.email,
      refund,
    };
    console.log(body);
    await orderCancel(body).then((r) => {
      if (!r) {
        setPendingCancelOrder(false);
        toast.error(t("error_try_again"));
      } else {
        toast.success(t("order_canceled"));
        fetch();
        setShowOrderCancel(false);
        setPendingCancelOrder(false);
      }
    });
  };

  const onSearch = async (query) => {
    if (query == "") {
      setOrders(await listLatest());
      setSearchTimer(null);
    } else {
      if (searchTimer) clearTimeout(searchTimer);
      let timer = setTimeout(async () => {
        setOrders(await listLatest(query));
      }, 1500);
      setSearchTimer(timer);
    }
  };

  const search = (query) => {
    setSearchQuery(query);
    onSearch(query);
  };

  const onResendTickets = async (id, email) => {
    await onResend(id, email).then((r) => {
      if (!r) toast.error(t("error_try_again"));
      else toast.success(t("tickets_sent_again"));
    });
  };

  const onClickSearch = async () => {
    setOrders(await listLatest(searchQuery));
  };

  function summarizeJson(data, indent = "") {
    let summary = "";
    if (Array.isArray(data)) {
      summary += "[\n";
      data.forEach((item) => {
        summary += `${indent}  ${summarizeJson(item, indent + "  ")}\n`;
      });
      summary += `${indent}]\n`;
    } else if (typeof data === "object" && data !== null) {
      summary += "{\n";
      for (const key in data) {
        summary += `${indent}  "${key}": ${summarizeJson(
          data[key],
          indent + "  "
        )}\n`;
      }
      summary += `${indent}}\n`;
    } else {
      // Çift tırnak içinde değerleri göster
      let value = typeof data === "string" ? `"${data}"` : data;
      return `${value}`;
    }
    return summary;
  }

  function downloadTextAsTxt(text, fileName) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const onClickDownloadInfos = (data) => {
    const summary = summarizeJson(data);
    downloadTextAsTxt(summary, "siparis-detayi.txt");
  };

  const onClickReminder = async () => {
    console.log(selectedEvent);
    if (customImage) {
      await saveImage(customImage).then(async (imgUrl) => {
        if (!imgUrl) toast.error(t("error_try_again"));
        else {
          await remindCountryBased({
            eventId: selectedEvent,
            customImage: imgUrl,
          }).then((r) => {
            toast.success(t("mails_sent"));
          });
        }
      });
    } else {
      await remindCountryBased({
        eventId: selectedEvent,
        customImage: null,
      }).then((r) => {
        toast.success(t("mails_sent"));
      });
    }
    setIsReminderModalOpen(false);
  };

  const changeImg = (e) => {
    if (e.target.files[0].size > 5000000) {
      toast("Yüklenen resim 5MB fazla olamaz");
      e.target.value = "";
      e.target.type = "file";
    } else {
      let list = Array.from(e.target.files);
      setCustomImage(list[0]);
    }
  };

  const onClickReturnAllTickets = () => {};

  useEffect(() => {
    fetch();
  }, []);

  let ctmrArray = [0, 1];
  let customer = {};

  return (
    <Box>
      <Stack display="flex" direction="column" spacing={4}>
        {/* first Part */}

        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          style={{
            minHeight: "fit-content!important",
            backgroundColor: "#ffd283",
            height: "fit-content!important",
          }}
          className={classes.scrollable}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            ETKİNLİK BİLGİLERİ
          </Typography>
          {isLoading && <Spinner />}
          <Box
            sx={{
              backgroundColor: "#fff0d7",
            }}
          >
            <Stack
              style={{ backgroundColor: "#ffd283" }}
              display="flex"
              direction="column"
              paddingTop="20px"
              spacing={2}
            >
              {events.filter((it) => !it.isEnded).length ? (
                <>
                  {events
                    .filter((it) => !it.isEnded)
                    .map((eve, ind) => (
                      <Box key={ind} sx={{ borderBottom: "2px solid #df0c62" }}>
                        <Accordion
                          sx={{
                            backgroundColor: "#ffd283",
                            boxShadow: "none",
                          }}
                          onChange={() => {
                            toggleEve(eve._id);
                          }}
                          expanded={eve.active || false}
                        >
                          <AccordionSummary
                            sx={{ paddingRight: "0", textAlign: "center" }}
                            expandIcon={
                              <ExpandMore sx={{ color: "#df0c62" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack
                              style={{}}
                              display="flex"
                              direction="row"
                              width="100%"
                            >
                              <Box flex={2} color="#df0c62">
                                <Typography
                                  style={{ textAlign: "start" }}
                                  fontWeight="bold"
                                >
                                  {eve.title}
                                </Typography>
                              </Box>
                              <Box flex={2} color="#df0c62">
                                <Typography
                                  style={{ textAlign: "end" }}
                                  fontWeight="bold"
                                >
                                  {eve.isEnded
                                    ? "Sona Erdi"
                                    : "Satışı Devam Ediyor"}
                                </Typography>
                              </Box>

                              <Box flex={1} color="#df0c62">
                                <Typography
                                  fontWeight="bold"
                                  sx={{ paddingLeft: "4px", textAlign: "end" }}
                                >
                                  Satıldı: {showDiscountPrice(eve.soldTickets)}
                                </Typography>
                              </Box>
                              {/* End of Data Title */}
                            </Stack>
                          </AccordionSummary>
                          {eve.loaded ? (
                            <AccordionDetails>
                              <div
                                className="container-fluid my-4"
                                style={{ color: "#df0c62" }}
                              >
                                <hr />
                                <div className="row">
                                  <div className="col-6 overall">
                                    <img
                                      src={CDN_URL + eve.image}
                                      alt={eve.title}
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-6 stat">
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Tür:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.type}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Şehir:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.city}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Mekan:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.location}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Organizatör:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.promoter
                                          ? eve.promoter.businessName
                                          : "eTicket Al"}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Tarih:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.date?.split("T")[0]}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Katılım:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.usedPercent}%
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="container-fluid my-4"
                                style={{ color: "#df0c62" }}
                              >
                                <hr />
                                <div className="row">
                                  <div className="col-6 overall">
                                    <h5 style={{ width: "100%" }}>
                                      {/* Bilet Türü 1 */}
                                      Bilet türü:
                                    </h5>
                                    {eve?.types?.map((ttype, tind) => (
                                      <div
                                        key={tind}
                                        style={{
                                          width: "90%",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        {ttype.title} - {ttype.price}{" "}
                                        {eve.currency} -{" "}
                                        <strong>
                                          {showDiscountPrice(ttype.sold)} (
                                          {showDiscountPrice(ttype.sold) *
                                            ttype.price}{" "}
                                          {eve.currency})
                                        </strong>
                                      </div>
                                    ))}
                                    <hr />
                                    <h5 style={{ width: "100%" }}>
                                      Ödeme türü:
                                    </h5>
                                    <div
                                      style={{
                                        width: "90%",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Kart -{" "}
                                      {showDiscountPrice(eve.total?.cardVal)} -{" "}
                                      <strong>
                                        {showDiscountPrice(eve.total?.card)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        width: "90%",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Nakit -{" "}
                                      {showDiscountPrice(eve.total?.cashVal)} -{" "}
                                      <strong>
                                        {showDiscountPrice(eve.total?.cash)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        width: "90%",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Misafir -{" "}
                                      {showDiscountPrice(eve.total?.guestVal)} -{" "}
                                      {/* Misafir - 0 -{" "} */}
                                      <strong>
                                        {/* {eve.total.guest} {eve.currency} */}
                                        Ücretsiz
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-6 stat">
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Satış / Katılım:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(
                                          eve.soldToUsed?.sold
                                        )}{" "}
                                        /{" "}
                                        {showDiscountPrice(
                                          eve.soldToUsed?.used
                                        )}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Toplam Kazanç:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.total)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Platform Kazancı ({eve.commission}%):{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.earning)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Organizatör Kazançı:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.proearn)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <button
                                className="underline me-5"
                                style={{
                                  color: "white",
                                  backgroundColor: "#df0c62",
                                }}
                                // onClick={() => onClickReminder(eve._id)}
                                onClick={() => {
                                  setIsReminderModalOpen(true);
                                  setSelectedEvent(eve._id);
                                }}
                              >
                                Ülke bazlı tanıtım maili gönder (
                                {eve.emailListSize})
                              </button>
                              <button
                                className="underline"
                                style={{
                                  color: "white",
                                  backgroundColor: "#df0c62",
                                }}
                                // onClick={() => onClickReminder(eve._id)}
                                onClick={() => {
                                  setIsReturnAllTicketsModalOpen(true);
                                  setSelectedEvent(eve._id);
                                }}
                              >
                                Tüm biletleri iade et
                              </button>
                            </AccordionDetails>
                          ) : (
                            <AccordionDetailsSkeleton />
                          )}
                        </Accordion>
                        {/* Accordion End */}
                      </Box>
                    ))}
                </>
              ) : null}

              {showEnded && events.filter((it) => it.isEnded).length ? (
                <>
                  {events
                    .filter((it) => it.isEnded)
                    .map((eve, ind) => (
                      <Box
                        key={ind + events.filter((it) => !it.isEnded).length}
                        sx={{ borderBottom: "2px solid #df0c62" }}
                      >
                        <Accordion
                          sx={{
                            backgroundColor: "#ffd283",
                            boxShadow: "none",
                          }}
                          onChange={() => toggleEve(eve._id)}
                          expanded={eve.active || false}
                        >
                          <AccordionSummary
                            sx={{ paddingRight: "0", textAlign: "center" }}
                            expandIcon={
                              <ExpandMore sx={{ color: "#df0c62" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack
                              style={{}}
                              display="flex"
                              direction="row"
                              width="100%"
                            >
                              <Box flex={2} color="#df0c62">
                                <Typography
                                  style={{ textAlign: "start" }}
                                  fontWeight="bold"
                                >
                                  {eve.title}
                                </Typography>
                              </Box>
                              <Box flex={2} color="#df0c62">
                                <Typography
                                  style={{ textAlign: "end" }}
                                  fontWeight="bold"
                                >
                                  {eve.isEnded
                                    ? "Sona Erdi"
                                    : "Satışı Devam Ediyor"}
                                </Typography>
                              </Box>

                              <Box flex={1} color="#df0c62">
                                <Typography
                                  fontWeight="bold"
                                  sx={{ paddingLeft: "4px", textAlign: "end" }}
                                >
                                  Satıldı: {showDiscountPrice(eve.soldTickets)}
                                </Typography>
                              </Box>
                              {/* End of Data Title */}
                            </Stack>
                          </AccordionSummary>
                          {eve.loaded ? (
                            <AccordionDetails>
                              <div
                                className="container-fluid my-4"
                                style={{ color: "#df0c62" }}
                              >
                                <hr />
                                <div className="row">
                                  <div className="col-6 overall">
                                    <img
                                      src={CDN_URL + eve.image}
                                      alt={eve.title}
                                      width="100%"
                                    />
                                  </div>
                                  <div className="col-6 stat">
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Tür:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.type}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Şehir:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.city}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Mekan:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.location}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Organizatör:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.promoter
                                          ? eve.promoter.businessName
                                          : "eTicket Al"}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Tarih:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.date?.split("T")[0]}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Katılım:{" "}
                                      <strong style={{ float: "right" }}>
                                        {eve.usedPercent}%
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="container-fluid my-4"
                                style={{ color: "#df0c62" }}
                              >
                                <hr />
                                <div className="row">
                                  <div className="col-6 overall">
                                    <h5 style={{ width: "100%" }}>
                                      {/* Bilet Türü 2 */}
                                      Bilet türü:
                                    </h5>
                                    {eve?.types?.map((ttype, tind) => (
                                      <div
                                        key={tind}
                                        style={{
                                          width: "90%",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        {ttype.title} - {ttype.price}{" "}
                                        {eve.currency} -{" "}
                                        <strong>
                                          {showDiscountPrice(ttype.sold)} (
                                          {showDiscountPrice(ttype.sold) *
                                            ttype.price}{" "}
                                          {eve.currency})
                                        </strong>
                                      </div>
                                    ))}
                                    <hr />
                                    <h5 style={{ width: "100%" }}>
                                      Ödeme türü:
                                    </h5>
                                    <div
                                      style={{
                                        width: "90%",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Kart -{" "}
                                      {showDiscountPrice(eve.total?.cardVal)} -{" "}
                                      <strong>
                                        {showDiscountPrice(eve.total?.card)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        width: "90%",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Nakit -{" "}
                                      {showDiscountPrice(eve.total?.cashVal)} -{" "}
                                      <strong>
                                        {showDiscountPrice(eve.total?.cash)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-6 stat">
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Satış / Katılım:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(
                                          eve.soldToUsed?.sold
                                        )}{" "}
                                        /{" "}
                                        {showDiscountPrice(
                                          eve.soldToUsed?.used
                                        )}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Toplam Kazanç:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.total)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Platform Kazancı ({eve.commission}%):{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.earning)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #df0c62",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Organizatör Kazançı:{" "}
                                      <strong style={{ float: "right" }}>
                                        {showDiscountPrice(eve.total?.proearn)}{" "}
                                        {eve.currency}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </AccordionDetails>
                          ) : (
                            <AccordionDetailsSkeleton />
                          )}
                        </Accordion>
                        {/* Accordion End */}
                      </Box>
                    ))}
                </>
              ) : null}
              {events?.filter((it) => it.isEnded)?.length ? (
                <div
                  onClick={() => {
                    setShowEnded(!showEnded);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#df0c62",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  {showEnded ? "Geçmişleri Gizle" : "Geçmişleri Göster"}
                </div>
              ) : null}
            </Stack>
          </Box>
          {/* End Of Organizer Fee Information */}
        </Stack>

        {/* Search Bar */}
        <SearchBar
          query={searchQuery}
          changeQuery={setSearchQuery}
          onClickSearch={onClickSearch}
        />

        {/* ÜYE BİLGİLERİ */}
        <Stack
          display="flex"
          direction="column"
          padding="2%"
          spacing={2}
          sx={{ backgroundColor: "#ffd283", height: "fit-content" }}
          className={classes.member}
        >
          {/* Title */}
          <Typography variant="h5" fontWeight="bold" color="#df0c62">
            {searchQuery != "" ? "Bulunan Sonuçlar" : "Son Siparişler"}
          </Typography>
          {isLoading && <Spinner />}
          {orders.map((c, inx) => {
            return (
              <Box key={inx}>
                <Accordion
                  sx={{
                    backgroundColor: "#fff0d7",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    sx={{ paddingRight: "0" }}
                    expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Stack display="flex" direction="row" width="100%">
                      <Typography
                        color="#df0c62"
                        fontWeight="bold"
                        style={
                          c.tickets.reduce(
                            (a, c) => (c.status === true ? a + 1 : a),
                            0
                          ) === c.tickets.length
                            ? {}
                            : { textDecoration: "line-through", opacity: 0.5 }
                        }
                      >
                        <>
                          {c.customer ? (
                            <>
                              {c.customer.firstName + " " + c.customer.lastName}
                            </>
                          ) : c.guest ? (
                            <>{c.guest.firstName + " " + c.guest.lastName}</>
                          ) : null}
                          {" ("}
                          {c.tickets.length}
                          {" Adet)"} {c.tickets[0].event?.currency.symbol}
                          {c.total}
                        </>
                      </Typography>
                      <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                        <Typography
                          color="#df0c62"
                          fontWeight="bold"
                          textAlign="end"
                        >
                          Bilgileri Gör
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Detail parent Stack */}
                    <Stack display="flex" direction="column" spacing={4}>
                      {/* First Part Stack */}
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={2}
                        sx={{ backgroundColor: "#fff0d7" }}
                      >
                        {/* Left Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.firstName || c.guest?.firstName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.phoneNumber ||
                                  c.guest?.phoneNumber}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                Toplam {c.tickets[0].event?.currency.symbol}
                                {c.total}&nbsp;(
                                {c.payment === "cash" && "Nakit"}
                                {c.payment === "card" && "Kredi Kartı"}
                                {c.payment === "guest" && "Misafir"})
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                Ödeme Numarası:{" "}
                                <a
                                  target="_blank"
                                  href={
                                    "https://dashboard.stripe.com/payments/" +
                                    c.securityKey?.split("_secret_")[0]
                                  }
                                >
                                  {c.securityKey?.split("_secret_")[0]}
                                </a>
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                        {/* Right Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.lastName || c.guest?.lastName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.email || c.guest?.email}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.created?.split("T")[0] +
                                  " " +
                                  c.created?.split("T")[1].split(".")[0]}
                              </Typography>
                            </Box>
                            {c.invoiceAddress ? (
                              <Box
                                padding="8px 0"
                                sx={{ borderBottom: "2px solid #feb659" }}
                              >
                                <Typography color="#df0c62" fontWeight="bold">
                                  Fatura Adresi:{" "}
                                  <span>
                                    {c.invoiceAddress.address},{" "}
                                    {c.invoiceAddress.addressContinue},{" "}
                                    {c.invoiceAddress.city},{" "}
                                    {c.invoiceAddress.country}
                                  </span>
                                </Typography>
                              </Box>
                            ) : null}
                          </Stack>
                        </Box>
                        {/* End Of First Part Stack */}
                      </Stack>

                      <Stack
                        className="d-flex"
                        display="flex"
                        direction="column"
                        spacing={2}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <Typography
                            color="#df0c62"
                            variant="h5"
                            fontWeight="bold"
                          >
                            Etkinlik
                          </Typography>
                          <div className="d-flex gap-2">
                            <Typography
                              className="border p-2 border-2 rounded-5 border-danger cursor-pointer"
                              color="#df0c62"
                              variant="h7"
                              fontWeight="semibold"
                              onClick={() => onClickDownloadInfos(c)}
                            >
                              Bilgileri İndir
                            </Typography>
                            <Typography
                              className="border p-2 border-2 rounded-5 border-danger cursor-pointer"
                              color="#df0c62"
                              variant="h7"
                              fontWeight="semibold"
                              onClick={() => {
                                setShowOrderCancel(true);
                                setOrderToCancel(c);
                              }}
                              //onClick={() => onCancelOrder(c)}
                            >
                              Sipariş İptal
                            </Typography>
                          </div>
                        </div>
                        <Stack display="flex" direction="column" spacing={2}>
                          <Box
                            padding="8px 0"
                            sx={{
                              borderBottom: "2px solid #feb659",
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography color="#df0c62" fontWeight="bold">
                              {c.tickets[0].event.title} at{" "}
                              {c.tickets[0].event.location?.name}
                            </Typography>
                            {c.tickets[0].event.promoter ? (
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.tickets[0].event.promoter.firstName +
                                  " " +
                                  c.tickets[0].event.promoter.lastName}
                              </Typography>
                            ) : null}
                          </Box>
                        </Stack>
                      </Stack>

                      <Stack display="flex" direction="column" spacing={2}>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <Typography
                            color="#df0c62"
                            variant="h5"
                            fontWeight="bold"
                          >
                            Biletler
                          </Typography>
                          <div
                            className="centeredFlex d-inline-flex"
                            style={
                              c.tickets.find((it) => it.canceled)
                                ? { opacity: 0.7, display: "inline-flex" }
                                : { display: "inline-flex" }
                            }
                          >
                            <input
                              className="simpleInput me-3"
                              type="text"
                              value={c.emailToSend}
                              placeholder={c.guest?.email || c.customer?.email}
                              onChange={(e) => {
                                let tc = [...orders];
                                tc[inx].emailToSend = e.target.value;
                                setOrders(tc);
                              }}
                            />
                            <div
                              className="centeredFlex d-inline-flex"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Biletleri göndermek istediğinizde emin misiniz?"
                                  )
                                )
                                  onResendTickets(c._id, c.emailToSend);
                              }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                Mail Gönder
                              </Typography>
                              <IconButton size="medium">
                                <Email sx={{ color: "#df0c62" }} />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        {c.tickets.map((ticket, tindex) => (
                          <div
                            style={ticket.canceled ? { opacity: 0.4 } : null}
                          >
                            <Box
                              padding="8px 0"
                              sx={{
                                borderBottom: "2px solid #feb659",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                <span
                                  style={
                                    ticket.canceled
                                      ? { textDecoration: "line-through" }
                                      : null
                                  }
                                >
                                  {ticket.event.plan.startsFromOne
                                    ? ticket.trueLabel
                                    : ticket.label}{" "}
                                  -{" "}
                                  {ticket.event.currency.symbol + ticket.price}
                                  <span className="d-block">
                                    Kullanım Tarihi :{" "}
                                    {ticket.usedTime
                                      ? ticket.usedTime?.split("T")[0] +
                                        " " +
                                        ticket.usedTime
                                          ?.split("T")[1]
                                          .split(".")[0]
                                      : "Henüz kullanılmadı"}
                                  </span>
                                </span>
                              </Typography>

                              {ticket.canceled ? (
                                <Typography color="#df0c62" fontWeight="bold">
                                  Iptal Edildi
                                </Typography>
                              ) : null}
                              <div>
                                {/* <a
                                  href={
                                    process.env.REACT_APP_CDN_URL +
                                    // "http://193.203.190.240:8000/" +
                                    // "https://eticketal.com/" +
                                    // "localhost:8000/" +
                                    "pdfs/" +
                                    ticket._id +
                                    ".pdf"
                                  }
                                  target="_blank"
                                  className="centeredFlex d-inline-flex me-4"
                                  style={
                                    c.tickets.find((it) => it.canceled)
                                      ? {
                                          pointerEvents: "none",
                                          display: "inline-flex",
                                        }
                                      : { display: "inline-flex" }
                                  }
                                >
                                  <Typography color="#df0c62" fontWeight="bold">
                                    Bilet İndir
                                  </Typography>
                                  <IconButton size="medium">
                                    <Save sx={{ color: "#df0c62" }} />
                                  </IconButton>
                                </a> */}
                                <Dialog
                                  open={loading}
                                  PaperProps={{
                                    style: {
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)", // Semi-transparent black
                                      boxShadow: "none",
                                      overflow: "hidden",
                                    },
                                  }}
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <DialogContent
                                    style={{
                                      backgroundColor: "transparent",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CircularProgress />
                                    <p>İndiriliyor, lütfen bekleyin...</p>
                                  </DialogContent>
                                </Dialog>
                                <button
                                  onClick={async () => {
                                    setLoading(true); // Start loading
                                    try {
                                      const response = await axios.get(
                                        `${process.env.REACT_APP_BACK_URL}/api/ticket/download/${ticket._id}`,
                                        {
                                          responseType: "blob", // Important to receive the data as a Blob
                                        }
                                      );

                                      // Use file-saver to save the Blob as a file
                                      const blob = new Blob([response.data], {
                                        type: "application/pdf",
                                      });
                                      saveAs(blob, `${ticket._id}.pdf`);
                                    } catch (error) {
                                      console.error(
                                        "Error downloading the PDF:",
                                        error
                                      );
                                      // Optionally, display an error message to the user
                                    } finally {
                                      setLoading(false); // Stop loading
                                    }
                                  }}
                                  className="centeredFlex d-inline-flex me-4"
                                  style={
                                    c.tickets.find((it) => it.canceled)
                                      ? {
                                          pointerEvents: "none",
                                          display: "inline-flex",
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }
                                      : {
                                          display: "inline-flex",
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }
                                  }
                                >
                                  <Typography color="#df0c62" fontWeight="bold">
                                    Bilet İndir
                                  </Typography>
                                  <IconButton size="medium">
                                    <Save sx={{ color: "#df0c62" }} />
                                  </IconButton>
                                </button>
                                <div
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Bilet sılıyorsunuz. Emin misiniz?"
                                      )
                                    )
                                      onCancel(ticket._id);
                                  }}
                                  className="centeredFlex"
                                  style={
                                    ticket.canceled
                                      ? {
                                          pointerEvents: "none",
                                          display: "inline-flex",
                                        }
                                      : { display: "inline-flex" }
                                  }
                                >
                                  <Typography color="#df0c62" fontWeight="bold">
                                    Iptal Et
                                  </Typography>
                                  <IconButton size="medium">
                                    <Close sx={{ color: "#df0c62" }} />
                                  </IconButton>
                                </div>
                              </div>
                            </Box>
                          </div>
                        ))}
                      </Stack>
                      {/* End Of Accordion Detail Stack */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {/* Accordion End */}
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Modal
        open={showOrderCancel}
        onClose={() => {
          setShowOrderCancel(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff0d7",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* Detail parent Stack */}
          <Stack display="flex" direction="column" spacing={2}></Stack>
          <div className="d-flex align-items-center gap-2 mb-2">
            <input
              type="checkbox"
              id="notifyCustomer"
              defaultChecked={notifyCustomer}
              onChange={(e) => setNotifyCustomer(e.target.checked)}
            />
            <label
              htmlFor="notifyCustomer"
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
            >
              Müşteriyi Email Gönder
            </label>
          </div>
          <div className="d-flex align-items-center gap-2 mb-2">
            <input
              type="checkbox"
              id="refund"
              defaultChecked={refund}
              onChange={(e) => setRefund(e.target.checked)}
            />
            <label
              htmlFor="refund"
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
            >
              Para iadesi yapılsın
            </label>
          </div>
          <div>
            <input
              className="mb-4 modalFormInput w-100"
              type="text"
              name="title"
              placeholder={orderToCancel?.tickets[0]?.customer?.email}
              onChange={(e) => setCustomerEmail(e.target.value)}
              value={customerEmail}
            />
          </div>
          <div>
            <textarea
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
              rows={4}
              type="text"
              name="description"
              placeholder="İptal nedeni"
              onChange={(e) => setCancelReason(e.target.value)}
              value={cancelReason}
            />
          </div>
          <div className="justify-content-between d-flex">
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={onCancelOrder}
              disabled={pendingCancelOrder}
            >
              Siparişi İptal Et
            </button>
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={() => setShowOrderCancel(false)}
            >
              Kapat
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isReminderModalOpen}
        onClose={() => {
          setIsReminderModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff0d7",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* Detail parent Stack */}
          <Stack display="flex" direction="column" spacing={2}></Stack>
          <div className="d-flex flex-row">
            <Box
              className="mb-4 modalFormInput d-flex align-items-center justify-content-between"
              onClick={(e) => {
                document.querySelector("#locationImage2").click();
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              Resim
              {customImage ? (
                <>
                  {typeof customImage != "string" ? (
                    <img
                      src={URL.createObjectURL(customImage)}
                      height="50px"
                      width="auto"
                    />
                  ) : (
                    <img
                      src={CDN_URL + customImage}
                      height="50px"
                      width="auto"
                    />
                  )}
                </>
              ) : null}
            </Box>
            <input
              className="mb-4"
              type="file"
              id="locationImage2"
              value={customImage ? customImage.filename : null}
              name="image"
              onChange={(e) => {
                changeImg(e);
              }}
            />
          </div>
          <div className="justify-content-between d-flex">
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={onClickReminder}
            >
              Mailleri Gönder
            </button>
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={() => setIsReminderModalOpen(false)}
            >
              Kapat
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isReturnAllTicketsModalOpen}
        onClose={() => {
          setIsReminderModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff0d7",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* Detail parent Stack */}
          <Stack display="flex" direction="column" spacing={2}></Stack>
          <div className="d-flex flex-row mb-1">
            Etkinliğe ait tüm biletleri iptal etmek istediğinize emin misiniz?
          </div>
          <div className="d-flex align-items-center gap-2 mb-2">
            <input
              type="checkbox"
              id="notifyCustomer"
              defaultChecked={notifyAllCustomer}
              onChange={(e) => setNotifyAllCustomer(e.target.checked)}
            />
            <label
              htmlFor="notifyCustomer"
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
            >
              Müşterilere Email Gönder
            </label>
          </div>
          <div className="d-flex align-items-center gap-2 mb-2">
            <input
              type="checkbox"
              id="refund"
              defaultChecked={allRefund}
              onChange={(e) => setAllRefund(e.target.checked)}
            />
            <label
              htmlFor="refund"
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
            >
              Para iadeleri yapılsın
            </label>
          </div>
          <div>
            <textarea
              style={{
                outline: "none",
                width: "97%",
                border: "none",
                color: "#df0c62",
                backgroundColor: "transparent",
              }}
              rows={4}
              type="text"
              name="description"
              placeholder="İptal nedeni"
              onChange={(e) => setAllCancelReason(e.target.value)}
              value={allCancelReason}
            />
          </div>
          <div className="justify-content-between d-flex">
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={onAllCancelOrder}
            >
              Tüm Biletleri İptal Et
            </button>
            <button
              style={{
                backgroundColor: "#df0c62",
                color: "#fff",
                padding: "4px",
              }}
              onClick={() => setIsReturnAllTicketsModalOpen(false)}
            >
              Kapat
            </button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default SaleDesktop;
