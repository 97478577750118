// // Seatchart.jsx
// import React, { forwardRef, useEffect, useRef } from 'react';
// import SeatchartJS from 'seatchart';
// import './index.css';
// import { useTranslation } from 'react-i18next';
// import { MdImage, MdSchema, MdZoomIn, MdZoomOut } from 'react-icons/md';
// import { Modal } from '@mui/material';
// import { usePinch } from '@use-gesture/react';
// import Cookies from 'js-cookie';
// import { toast } from 'react-toastify';

// const setForwardedRef = (ref, value) => {
//   if (typeof ref === 'function') {
//     ref(value);
//   } else if (ref) {
//     ref.current = value;
//   }
// };

// const Seatchart = forwardRef(
//   ({ options, curr, planScheme, planImage, trueAdmin }, ref) => {
//     const CDN_URL = process.env.REACT_APP_CDN_URL || 'YOUR_CDN_URL';
//     const seatchartRef = useRef();
//     const elementRef = useRef(null);
//     const [zoomState, setZoomState] = React.useState(0.8);
//     const [schemeOpen, setSchemeOpen] = React.useState(false);
//     const [imageOpen, setImageOpen] = React.useState(false);
//     const { t } = useTranslation();
//     const [scrollPosition, setScrollPosition] = React.useState({
//       top: 0,
//       left: 0,
//     });

//     useEffect(() => {
//       if (elementRef.current && !seatchartRef.current) {

//         seatchartRef.current = new SeatchartJS(elementRef.current, {
//           ...options,
//           cart: { currency: curr },
//           onSeatClicked: (seat) => {
//             const { row, col } = seat;
//             const isReservedByAdmin = options.map.reservedByAdminSeats &&
//               options.map.reservedByAdminSeats.some(
//                 (s) => s.row === row && s.col === col
//               );
//             return !isReservedByAdmin;
//           },
//         });

//         setForwardedRef(ref, seatchartRef.current);

//         // Apply reserved-by-admin styling based on seat label text
//         options.map.reservedByAdminSeats.forEach(({ row, col }) => {
//           const rowLabel = String.fromCharCode(65 + row); // 65 is the char code for 'A'
//           const seatLabel = `${rowLabel}${col + 1}`; // Example: A1, B2, etc.

//           const seatElement = [...elementRef.current.querySelectorAll('.sc-seat')]
//             .find(el => el.textContent === seatLabel);

//           if (seatElement) {
//             seatElement.classList.remove('sc-seat-available');
//             seatElement.classList.add('reserved-by-admin');

//             // Apply inline styles to ensure appearance
//             seatElement.style.backgroundColor = trueAdmin ? 'black' : "";
//             seatElement.style.color = trueAdmin ? "white" : "black";
//             seatElement.style.cursor = trueAdmin ? "" : 'not-allowed';
//             seatElement.style.opacity = trueAdmin ? '1' : "0.2";
//             seatElement.style.pointerEvents = trueAdmin ? "" : 'none';
//           }
//         });

//         // Apply initial zoom and other event listeners
//         const map = elementRef.current.querySelector('.sc-map-inner-container');
//         map.style.transform = 'scale(' + zoomState + ')';

//         const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//         if (mapIndexer) {
//           mapIndexer.style.transform = 'scale(' + zoomState + ')';
//         }

//         const object = elementRef.current.querySelector('.sc-map');
//         object.addEventListener('scroll', handleScroll);

//         object.scrollTo({
//           top: scrollPosition.top,
//           left: scrollPosition.left,
//         });

//       }
//     }, [options, curr, ref]);

//     const handleScroll = () => {
//       const { scrollTop, scrollLeft } =
//         elementRef.current.querySelector('.sc-map');
//       setScrollPosition({ top: scrollTop, left: scrollLeft });
//     };

//     const zoomIn = () => {
//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       map.style.transform = 'scale(' + (zoomState + 0.1) + ')';

//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//       if (mapIndexer) {
//         mapIndexer.style.transform = 'scale(' + (zoomState + 0.1) + ')';
//       }
//       setZoomState(zoomState + 0.1);
//     };

//     const zoomOut = () => {
//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       map.style.transform = 'scale(' + (zoomState - 0.1) + ')';

//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//       if (mapIndexer) {
//         mapIndexer.style.transform = 'scale(' + (zoomState - 0.1) + ')';
//       }
//       setZoomState(zoomState - 0.1);
//     };

//     const bind = usePinch((state) => doSomethingWith(state), []);

//     const doSomethingWith = (act) => {
//       act.event.stopPropagation();

//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');

//       let initTransVal = act.event.deltaY / 300;
//       let transVal = initTransVal > 0.1 ? 0.1 : initTransVal;
//       if (zoomState - transVal > 0.15 && zoomState - transVal < 1) {
//         map.style.transform = 'scale(' + (zoomState - transVal) + ')';
//         if (mapIndexer) {
//           mapIndexer.style.transform = 'scale(' + (zoomState - transVal) + ')';
//         }
//         setZoomState(zoomState - transVal);
//       }
//     };

//     return (
//       <div
//         className={
//           'seatPickerOuter' + (options.inverted ? ' inverted' : '')
//         }
//       >
//         <Modal
//           className="imageModal"
//           open={schemeOpen}
//           onClose={() => setSchemeOpen(false)}
//         >
//           <img src={CDN_URL + planScheme} alt="Plan Scheme" />
//         </Modal>
//         <Modal
//           className="imageModal"
//           open={imageOpen}
//           onClose={() => setImageOpen(false)}
//         >
//           <img src={CDN_URL + planImage} alt="Plan Image" />
//         </Modal>

//         <div className="zoomBox">
//           {planScheme && planScheme !== '' ? (
//             <div
//               style={{ userSelect: 'none' }}
//               onClick={() => setSchemeOpen(true)}
//             >
//               <MdSchema /> {t('Plan Scheme')}
//             </div>
//           ) : null}
//           {planImage && planImage !== '' ? (
//             <div
//               style={{ userSelect: 'none' }}
//               onClick={() => setImageOpen(true)}
//             >
//               <MdImage /> {t('Plan Image')}
//             </div>
//           ) : null}
//           <div style={{ userSelect: 'none' }} onClick={zoomIn}>
//             <MdZoomIn /> {t('Zoom In')}
//           </div>
//           <div style={{ userSelect: 'none' }} onClick={zoomOut}>
//             <MdZoomOut /> {t('Zoom Out')}
//           </div>
//         </div>
//         <Legend t={t} options={options} />
//         <div {...bind()} ref={elementRef} />
//       </div>
//     );
//   }
// );

// const Legend = ({ t, options }) => {
//   const seatTypes = Object.values(options.map.seatTypes);

//   return (
//     <div className="legend">


//     </div>
//   );
// };

// export default Seatchart;



// //Seatchart.jsx
// import React, { forwardRef, useEffect, useRef } from 'react';
// import SeatchartJS from 'seatchart';
// import './index.css';
// import { useTranslation } from 'react-i18next';
// import { MdImage, MdSchema, MdZoomIn, MdZoomOut } from 'react-icons/md';
// import { Modal } from '@mui/material';
// import { usePinch } from '@use-gesture/react';

// const setForwardedRef = (ref, value) => {
//   if (typeof ref === 'function') {
//     ref(value);
//   } else if (ref) {
//     ref.current = value;
//   }
// };

// const Seatchart = forwardRef(
//   ({ options, curr, planScheme, planImage }, ref) => {
//     const CDN_URL = process.env.REACT_APP_CDN_URL || 'YOUR_CDN_URL';
//     const seatchartRef = useRef();
//     const elementRef = useRef(null);
//     const [zoomState, setZoomState] = React.useState(0.8);
//     const [schemeOpen, setSchemeOpen] = React.useState(false);
//     const [imageOpen, setImageOpen] = React.useState(false);
//     const { t } = useTranslation();
//     const [scrollPosition, setScrollPosition] = React.useState({
//       top: 0,
//       left: 0,
//     });

//     useEffect(() => {
//       if (elementRef.current && !seatchartRef.current) {
//         // Initialize SeatchartJS with options
//         seatchartRef.current = new SeatchartJS(elementRef.current, {
//           ...options,
//           cart: { currency: curr },
//           renderSeat: (seat, defaultRenderer) => {
//             const { row, col } = seat;
//             const isReservedByAdmin =
//               options.map.reservedByAdminSeats &&
//               options.map.reservedByAdminSeats.some(
//                 (s) => s.row === row && s.col === col
//               );

//             if (isReservedByAdmin) {
//               // Apply fixed reserved-by-admin class for black color
//               seat.cssClass = 'reserved-by-admin';
//             }
//             return defaultRenderer(seat);
//           },
//           onSeatClicked: (seat) => {
//             const { row, col } = seat;
//             const isReservedByAdmin =
//               options.map.reservedByAdminSeats &&
//               options.map.reservedByAdminSeats.some(
//                 (s) => s.row === row && s.col === col
//               );

//             return !isReservedByAdmin; // Disable click for reserved-by-admin seats
//           },
//         });

//         setForwardedRef(ref, seatchartRef.current);

//         // Initial zoom
//         const map = elementRef.current.querySelector('.sc-map-inner-container');
//         map.style.transform = 'scale(' + zoomState + ')';

//         const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//         if (mapIndexer) {
//           mapIndexer.style.transform = 'scale(' + zoomState + ')';
//         }

//         const object = elementRef.current.querySelector('.sc-map');
//         object.addEventListener('scroll', handleScroll);

//         object.scrollTo({
//           top: scrollPosition.top,
//           left: scrollPosition.left,
//         });

//         return () => {
//           seatchartRef.current = undefined;
//           setForwardedRef(ref, undefined);
//           if (object) {
//             object.removeEventListener('scroll', handleScroll);
//           }
//         };
//       }
//     }, [options, curr, ref]);

//     const handleScroll = () => {
//       const { scrollTop, scrollLeft } =
//         elementRef.current.querySelector('.sc-map');
//       setScrollPosition({ top: scrollTop, left: scrollLeft });
//     };

//     const zoomIn = () => {
//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       map.style.transform = 'scale(' + (zoomState + 0.1) + ')';

//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//       if (mapIndexer) {
//         mapIndexer.style.transform = 'scale(' + (zoomState + 0.1) + ')';
//       }
//       setZoomState(zoomState + 0.1);
//     };

//     const zoomOut = () => {
//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       map.style.transform = 'scale(' + (zoomState - 0.1) + ')';

//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');
//       if (mapIndexer) {
//         mapIndexer.style.transform = 'scale(' + (zoomState - 0.1) + ')';
//       }
//       setZoomState(zoomState - 0.1);
//     };

//     const bind = usePinch((state) => doSomethingWith(state), []);

//     const doSomethingWith = (act) => {
//       act.event.stopPropagation();

//       const map = elementRef.current.querySelector('.sc-map-inner-container');
//       const mapIndexer = elementRef.current.querySelector('.sc-indexer');

//       let initTransVal = act.event.deltaY / 300;
//       let transVal = initTransVal > 0.1 ? 0.1 : initTransVal;
//       if (zoomState - transVal > 0.15 && zoomState - transVal < 1) {
//         map.style.transform = 'scale(' + (zoomState - transVal) + ')';
//         if (mapIndexer) {
//           mapIndexer.style.transform = 'scale(' + (zoomState - transVal) + ')';
//         }
//         setZoomState(zoomState - transVal);
//       }
//     };

//     return (
//       <div
//         className={
//           'seatPickerOuter' + (options.inverted ? ' inverted' : '')
//         }
//       >
//         <Modal
//           className="imageModal"
//           open={schemeOpen}
//           onClose={() => setSchemeOpen(false)}
//         >
//           <img src={CDN_URL + planScheme} alt="Plan Scheme" />
//         </Modal>
//         <Modal
//           className="imageModal"
//           open={imageOpen}
//           onClose={() => setImageOpen(false)}
//         >
//           <img src={CDN_URL + planImage} alt="Plan Image" />
//         </Modal>

//         <div className="zoomBox">
//           {planScheme && planScheme !== '' ? (
//             <div
//               style={{ userSelect: 'none' }}
//               onClick={() => setSchemeOpen(true)}
//             >
//               <MdSchema /> {t('Plan Scheme')}
//             </div>
//           ) : null}
//           {planImage && planImage !== '' ? (
//             <div
//               style={{ userSelect: 'none' }}
//               onClick={() => setImageOpen(true)}
//             >
//               <MdImage /> {t('Plan Image')}
//             </div>
//           ) : null}
//           <div style={{ userSelect: 'none' }} onClick={zoomIn}>
//             <MdZoomIn /> {t('Zoom In')}
//           </div>
//           <div style={{ userSelect: 'none' }} onClick={zoomOut}>
//             <MdZoomOut /> {t('Zoom Out')}
//           </div>
//         </div>
//         <Legend t={t} options={options} />
//         <div {...bind()} ref={elementRef} />
//       </div>
//     );
//   }
// );

// const Legend = ({ t, options }) => {
//   const seatTypes = Object.values(options.map.seatTypes);

//   return (
//     <div className="legend">

//     </div>
//   );
// };

// export default Seatchart;

// Seatchart.jsx
import React, { forwardRef, useEffect, useRef } from 'react';
import SeatchartJS from 'seatchart';
import './index.css';
import { useTranslation } from 'react-i18next';
import { MdImage, MdSchema, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { Modal } from '@mui/material';
import { usePinch } from '@use-gesture/react';
import Cookies from 'js-cookie';

const setForwardedRef = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

const Seatchart = forwardRef(
  ({ options, curr, planScheme, planImage, trueAdmin, promotion }, ref) => {
    const CDN_URL = process.env.REACT_APP_CDN_URL || 'YOUR_CDN_URL';
    const seatchartRef = useRef();
    const elementRef = useRef(null);
    const [zoomState, setZoomState] = React.useState(0.8);
    const [schemeOpen, setSchemeOpen] = React.useState(false);
    const [imageOpen, setImageOpen] = React.useState(false);
    const { t } = useTranslation();
    const [scrollPosition, setScrollPosition] = React.useState({
      top: 0,
      left: 0,
    });
    const [selectedSeats, setSelectedSeats] = React.useState([]);
    const [totalPrice, setTotalPrice] = React.useState(0);

    console.log("trueAdmin : ", trueAdmin)

    useEffect(() => {
      if (elementRef.current && !seatchartRef.current) {
        seatchartRef.current = new SeatchartJS(elementRef.current, {
          ...options,
          cart: { currency: curr },
          onSeatClicked: (seat) => {
            const { row, col } = seat;
            const isReservedByAdmin = options.map.reservedByAdminSeats &&
              options.map.reservedByAdminSeats.some(
                (s) => s.row === row && s.col === col
              );
            return !isReservedByAdmin;
          },
        });

        setForwardedRef(ref, seatchartRef.current);

        // Apply reserved-by-admin styling based on seat label text
        options.map.reservedByAdminSeats.forEach(({ row, col }) => {
          const rowLabel = String.fromCharCode(65 + row); // 65 is the char code for 'A'
          const seatLabel = `${rowLabel}${col + 1}`; // Example: A1, B2, etc.

          const seatElement = [...elementRef.current.querySelectorAll('.sc-seat')]
            .find(el => el.textContent === seatLabel);

          if (seatElement) {
            seatElement.classList.remove('sc-seat-available');
            seatElement.classList.add('reserved-by-admin');

            // Apply inline styles to ensure appearance
            seatElement.style.backgroundColor = trueAdmin ? 'black' : "";
            seatElement.style.color = trueAdmin ? "white" : "black";
            seatElement.style.cursor = trueAdmin ? "" : 'not-allowed';
            seatElement.style.opacity = trueAdmin ? '1' : "0.2";
            seatElement.style.pointerEvents = trueAdmin ? "" : 'none';
          }
        });

        // Apply initial zoom and other event listeners
        const map = elementRef.current.querySelector('.sc-map-inner-container');
        map.style.transform = 'scale(' + zoomState + ')';

        const mapIndexer = elementRef.current.querySelector('.sc-indexer');
        if (mapIndexer) {
          mapIndexer.style.transform = 'scale(' + zoomState + ')';
        }

        const object = elementRef.current.querySelector('.sc-map');
        object.addEventListener('scroll', handleScroll);

        object.scrollTo({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });

        return () => {
          seatchartRef.current = undefined;
          setForwardedRef(ref, undefined);
          if (object) {
            object.removeEventListener('scroll', handleScroll);
          }
        };
      }
    }, [options, curr, ref]);

    const handleScroll = () => {
      const { scrollTop, scrollLeft } =
        elementRef.current.querySelector('.sc-map');
      setScrollPosition({ top: scrollTop, left: scrollLeft });
    };

    const zoomIn = () => {
      const map = elementRef.current.querySelector('.sc-map-inner-container');
      map.style.transform = 'scale(' + (zoomState + 0.1) + ')';

      const mapIndexer = elementRef.current.querySelector('.sc-indexer');
      if (mapIndexer) {
        mapIndexer.style.transform = 'scale(' + (zoomState + 0.1) + ')';
      }
      setZoomState(zoomState + 0.1);
    };

    const zoomOut = () => {
      const map = elementRef.current.querySelector('.sc-map-inner-container');
      map.style.transform = 'scale(' + (zoomState - 0.1) + ')';

      const mapIndexer = elementRef.current.querySelector('.sc-indexer');
      if (mapIndexer) {
        mapIndexer.style.transform = 'scale(' + (zoomState - 0.1) + ')';
      }
      setZoomState(zoomState - 0.1);
    };

    const bind = usePinch((state) => doSomethingWith(state), []);

    const doSomethingWith = (act) => {
      act.event.stopPropagation();

      const map = elementRef.current.querySelector('.sc-map-inner-container');
      const mapIndexer = elementRef.current.querySelector('.sc-indexer');

      let initTransVal = act.event.deltaY / 300;
      let transVal = initTransVal > 0.1 ? 0.1 : initTransVal;
      if (zoomState - transVal > 0.15 && zoomState - transVal < 1) {
        map.style.transform = 'scale(' + (zoomState - transVal) + ')';
        if (mapIndexer) {
          mapIndexer.style.transform = 'scale(' + (zoomState - transVal) + ')';
        }
        setZoomState(zoomState - transVal);
      }
    };

    return (
      <div
        className={
          'seatPickerOuter' + (options.inverted ? ' inverted' : '')
        }
      >
        <Modal
          className="imageModal"
          open={schemeOpen}
          onClose={() => setSchemeOpen(false)}
        >
          <img src={CDN_URL + planScheme} alt="Plan Scheme" />
        </Modal>
        <Modal
          className="imageModal"
          open={imageOpen}
          onClose={() => setImageOpen(false)}
        >
          <img src={CDN_URL + planImage} alt="Plan Image" />
        </Modal>

        <div className="zoomBox">
          {planScheme && planScheme !== '' ? (
            <div
              style={{ userSelect: 'none' }}
              onClick={() => setSchemeOpen(true)}
            >
              <MdSchema /> {t('Plan Scheme')}
            </div>
          ) : null}
          {planImage && planImage !== '' ? (
            <div
              style={{ userSelect: 'none' }}
              onClick={() => setImageOpen(true)}
            >
              <MdImage /> {t('Plan Image')}
            </div>
          ) : null}
          <div style={{ userSelect: 'none' }} onClick={zoomIn}>
            <MdZoomIn /> {t('Zoom In')}
          </div>
          <div style={{ userSelect: 'none' }} onClick={zoomOut}>
            <MdZoomOut /> {t('Zoom Out')}
          </div>
        </div>
        <Legend t={t} options={options} />
        <div {...bind()} ref={elementRef} />
      </div>
    );
  }
);

const Legend = ({ t, options }) => {
  const seatTypes = Object.values(options.map.seatTypes);

  return (
    <div className="legend">


    </div>
  );
};

export default Seatchart;
